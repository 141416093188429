import React, { useContext } from "react"
import styled from "styled-components"
import { Helmet } from "react-helmet"

import BreadcrumbContainer from "../styles/BreadcrumbContainer"
import BreadcrumbLink from "../styles/BreadcrumbLink"

import DocFontSizeContext from "../components/DocFontSizeContext"

const HtmlContainer = styled.div`
  max-width: 900px;
  white-space: pre-wrap;
  padding: 0.5rem;
  font-size: 1.25rem;
  color: var(--primaryVariant);

  * {
    margin: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
    line-height: var(--doc-line-height)};
    color: var(--primary);

    font-size: var(--doc-font-size);
  }

  ol {
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 30px;
  }

  img {
    object-fit: contain;
    object-position: 50% 50%;
    display: block;
    background: var(--backgroundColor);
    overflow: hidden;

    -webkit-user-select: none;  /* Chrome all / Safari all */
    -moz-user-select: none;     /* Firefox all */
    -ms-user-select: none;      /* IE 10+ */
    user-select: none;          /* Likely future */ 
  }

  a { 
    color: var(--linkColor);
    font-weight: 700;
    text-decoration: underline;
  }

  .gatsby-resp-image-wrapper {
    @media (min-width: 689px) {
      height: 867px;
    }
    @media (max-width: 689px) {
      height: 119.2vw;
    }
  }

  .gatsby-resp-image-link {
    text-decoration: none;

    @media (min-width: 689px) {
      height: 823px;
    }
    @media (max-width: 689px) {
      height: 107.0vw;
    }
  }

  .gatsby-resp-image-background-image {
    @media (min-width: 689px) {
      height: 779px;
    }
    @media (max-width: 689px) {
      height: 94.2vw;
    }

    padding-bottom: 0px !important;
  }

  .gatsby-resp-image-image {
    box-shadow: none !important;
  }
`

const StyledH3 = styled.h3`
  color: var(--primaryVariant);
  font-size: var(--doc-header-size);
`

export default function DocsPage({ pageContext }) {
  const { name, cleanedHtml, breadcrumbs } = pageContext
  const [docFontSize] = useContext(DocFontSizeContext)

  return (
    <>
      <Helmet>
        <title>Docs: {name}</title>
      </Helmet>
      <BreadcrumbContainer>
        {breadcrumbs.map((breadcrumb, index) => {
          if (breadcrumbs.length === index + 1) return

          const slugSplit = breadcrumb.slug.split("/")
          const folder = slugSplit[slugSplit.length - 1]

          return (
            <span key={breadcrumb.slug}>
              <span> / </span>
              <BreadcrumbLink to={breadcrumb.slug}>{folder}</BreadcrumbLink>
            </span>
          )
        })}
      </BreadcrumbContainer>
      <StyledH3>{name}</StyledH3>
      <HtmlContainer
        fontSize={docFontSize}
        dangerouslySetInnerHTML={{ __html: cleanedHtml }}
      />
    </>
  )
}
